.firstElement {
  padding-top: 5rem;
}

.container {
  width: 100%;

  > section {
    display: grid;
    grid-template-columns: minmax(min-content, var(--section-max-width));
    justify-content: center;
    padding: 2rem 1rem;
    padding-bottom: 4rem;
  }
}

.caption {
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  text-align: center;
}

.imageContainer {
  position: relative;
  box-shadow:
    0px 1.9px 2.2px rgba(0, 0, 0, 0.014),
    0px 4.6px 5.3px rgba(0, 0, 0, 0.02),
    0px 8.6px 10px rgba(0, 0, 0, 0.025),
    0px 15.4px 17.9px rgba(0, 0, 0, 0.03),
    0px 28.8px 33.4px rgba(0, 0, 0, 0.036);

  border-radius: 1rem;
  overflow: hidden;
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;

  >section {
    max-width: var(--section-max-width);
    width: 100%;
    padding: 3rem;

    >h2 {
      text-align: center;
      color: #32b300;
    }
  }
}

.team {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  margin-top: 8rem;
}

.member {
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  justify-items: center;
  align-content: start;
  row-gap: 0.75rem;
  padding: 2rem;
  max-width: 25rem;

  h3 {
    margin: 0;
    color: #32b300;
    font-weight: 500;
  }

  p {
    margin: 0;
  }

  .role {
    text-transform: uppercase;
    font-size: 0.85rem;
    font-weight: 600;

    margin-bottom: 0.5rem;
  }
}

.avatar {
  position: relative;
  margin: 0;
  --side: 9rem;
  height: var(--side);
  width: var(--side);
  border-radius: 1000rem;
  overflow: hidden;
  aspect-ratio: 1 / 1;
  margin-bottom: 2rem;
} 

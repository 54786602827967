.link {
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    border-bottom: 0.125rem solid rgba(#4e5d69, 0.03);
  }

  a {
    flex: 1;
    color: #4e5d69;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1;

    padding: 1.25rem 1.75rem;

    cursor: pointer;

    span.materialIcons:after {
      transform: translateX(-15%);
      opacity: 0;
      content: 'navigate_next';
      color: #4e5d69;
    }

    span, span.materialIcons:after {
      transition: all 100ms linear;
    }

    &:hover,
    &.active {
      span:first-of-type {
        opacity: 0.5;
      }

      span.materialIcons:after {
        transform: translateX(0);
        opacity: 0.5;
      }
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.smallMenuContainer {
  display: flex;
  position: absolute;
  justify-content: flex-end;

  padding: 1rem;

  top: 0;
  right: 0;

  pointer-events: none;
  opacity: 0;
  transition: all 300ms ease-out;
  perspective: 2000px;
  will-change: opacity;

  &.active {
    pointer-events: unset;
    opacity: 1;
  }
}

.smallMenu {
  position: relative;

  max-width: 25rem;
  overflow: hidden;

  background-color: white;
  border: 0.225rem solid #f3f5f6;
  overflow: hidden;

  display: grid;
  grid-template-columns: 1fr;
  justify-content: start;
  align-content: start;

  transform: rotateX(-15deg);
  transform-origin: 50% -50px;
  transition: all 300ms ease-out;
  will-change: transform;

  &.active {
    transform: rotateX(0deg);
  }

  border-radius: 1rem;

  box-shadow: 0px 1.9px 2.2px rgba(0, 0, 0, 0.014),
    0px 4.6px 5.3px rgba(0, 0, 0, 0.02), 0px 8.6px 10px rgba(0, 0, 0, 0.025),
    0px 15.4px 17.9px rgba(0, 0, 0, 0.03),
    0px 28.8px 33.4px rgba(0, 0, 0, 0.036), 0px 69px 80px rgba(0, 0, 0, 0.05);

  .header {
    order: -2;

    padding: 1.25rem 1.5rem;
    padding-right: 1.15rem;

    display: grid;
    grid-template-columns: 1fr auto;

    .logoContainer {
      display: flex;
    }

    .closeButtonContainer {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .closeButton {
      &:after {
        content: 'close';
        color: #4e5d69;

        font-size: 1.35rem;
      }
    }
  }
}

.logoContainer {
  .logo,
  .backButton {
    will-change: transform, opacity;
    transition: all 300ms cubic-bezier(0, -0.01, 0.19, 0.99);
  }

  .backButton {
    position: absolute;
    opacity: 0;
    transform: translateX(100%);

    span:after {
      content: 'arrow_back';
      color: #4e5d69;
      font-size: 1.35rem;
    }
  }

  &.hasSelectedSection {
    .logo {
      transform: translateX(-50%);
      opacity: 0;
    }

    .backButton {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

.menuContainer {
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
}

.mainMenu {
  list-style: none;

  display: grid;
  grid-template-columns: 1fr;
  justify-content: start;
  align-content: start;

  > li {
    flex: 1;
  }

  transition: all 250ms cubic-bezier(0, -0.01, 0.19, 0.99);
  &.hasSelectedSection {
    transform: translateX(-50%);
  }
}

.productSectionsMenu {
  display: flex;
  position: absolute;

  padding: 1rem 1.25rem;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  overflow-y: auto;

  will-change: transform;
  transform: translateX(115%);

  background-color: white;
  transition: all 350ms cubic-bezier(0, -0.01, 0.19, 0.99);

  &.hasSelectedSection {
    transform: translateX(0);
  }

  box-shadow: 0px 1.9px 2.2px rgba(0, 0, 0, 0.014),
    0px 4.6px 5.3px rgba(0, 0, 0, 0.02), 0px 8.6px 10px rgba(0, 0, 0, 0.025),
    0px 15.4px 17.9px rgba(0, 0, 0, 0.03),
    0px 28.8px 33.4px rgba(0, 0, 0, 0.036), 0px 69px 80px rgba(0, 0, 0, 0.05);
}

.smallProductMenu {
  order: -1;

  display: grid;

  border-radius: 1rem;
  margin: 0rem 0.5rem;
  overflow: hidden;

  h3 {
    padding: 1.25rem;
    padding-bottom: 0.5rem;
    font-weight: 600;
    font-size: 0.9375rem;
  }
}

.sections {
  > li {
    button {
      width: 100%;
      text-align: left;

      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-rows: repeat(2, auto);
      grid-template-areas: 'title icon' 'description icon';
      align-items: center;
      column-gap: 3rem;

      padding: 1rem 1.25rem;

      position: relative;

      h4, p {
        font-size: 0.875rem;
      }

      h4, p, span {
        transition: all 200ms cubic-bezier(0.4, 0, 0.6, 1);
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: var(--linearGradient);
        opacity: 0;
        transition: all 300ms ease-out;
      }

      &:hover {
        h4, p, span {
          transform: translateX(0.5rem);
        }

        p {
          opacity: 0.9;
        }

        &:after {
          opacity: 1;
        }
      }
    }

    &:not(:last-of-type) {
      border-bottom: 0.125rem solid rgba(#32b300, 0.04);
    }

    h4 {
      grid-area: title;
      font-weight: 600;
      margin-bottom: 0.25rem;
    }

    p {
      grid-area: description;
      line-height: 1.35;
      max-width: 20rem;
      opacity: 0.7;
    }

    .materialIcons {
      grid-area: icon;
    }
  }
}

.collectionItemsList {
  list-style: none;
  display: grid;
  grid-auto-flow: row;
  row-gap: 2rem;

  padding: 0rem 0rem;
  padding-bottom: 1.5rem;

  &:first-of-type {
    padding-bottom: 2.5rem;
  }
}

.collectionTitle {
  margin-top: 0;
  font-size: 0.8125rem;
  text-transform: uppercase;
  color: rgba(#4e5d69, 0.75);
  font-weight: 700;

  margin-left: 0rem;
  margin-bottom: 1rem;
}

.product {
  a {
    display: grid;
    grid-template-columns: 2rem 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      'icon title'
      'icon description';

    column-gap: 1rem;
    row-gap: 0.15rem;
  }

  .iconContainer {
    position: relative;
    grid-area: icon;
    aspect-ratio: 1 / 1;
    margin-top: 0.25rem;
    transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    transition: 275ms all cubic-bezier(0.51, -0.72, 0.25, 1.75);
    will-change: transform;
  }

  &:hover {
    cursor: pointer;

    .iconContainer {
      transform: perspective(500px) rotateX(-30deg) rotateY(30deg)
        rotateZ(15deg);
    }

    p {
      color: rgba(#4e5d69, 1);
    }

    h4:after {
      transform: scaleX(1);
      opacity: 1;
    }
  }

  .title {
    grid-area: title;
  }

  .description {
    grid-area: description;
  }

  .chips {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    column-gap: 0.35rem;

    margin-top: 0.45rem;
    margin-bottom: 0.25rem;

    .chip {
      display: inline-flex;
      font-size: 0.75rem;
      font-weight: 600;
      color: rgba(#4E5D69, 0.8);
      background-color: #F2F2F2;

      padding: 0.08rem 0.525rem;
      border-radius: 100rem;
    }
  }

  h4 {
    font-size: 0.875rem;
    margin: 0;
    display: flex;
    align-items: center;
    transition: 200ms all ease-out;
    font-weight: 600;

    &:after {
      content: 'arrow_forward';
      font-family: var(--font-material-icons);
      line-height: 1;
      font-size: 0.875rem;

      margin-left: 0.35rem;
      margin-bottom: 0rem;
      transform: scaleX(0);
      opacity: 0;

      transition: 200ms all ease-out;
      will-change: transform, opacity;
    }
  }

  p {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.35rem;
    color: rgba(#4e5d69, 0.65);
    transition: 200ms all ease-out;
  }
}

.materialIcons {
  display: flex;

  &:after {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    font-family: var(--font-material-icons);
    font-weight: normal;
    font-style: normal;
    font-size: 1.75rem;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }
}

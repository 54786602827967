.container {
  display: flex;
  justify-content: center;
  width: 100%;

  margin-top: 7.5rem;

  padding-top: 5rem;
  padding-bottom: 15rem;

  padding-left: 1rem;
  padding-right: 1rem;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.section {
  text-align: center;

  .kicker {
    color: #32b300;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 700;
  }

  h2 {
    color: #4E5D69;
    font-size: 1.5rem;
    margin-bottom: 4rem;
  }

  p {
    max-width: 30rem;
    font-size: 1rem;
    line-height: 2;
  }
}

.buttons {
  margin-top: 5rem;

  font-weight: 600;

  display: grid;
  align-items: center;
  justify-items: center;
  grid-auto-flow: column;

  column-gap: 2rem;

  a[data-type=primary] {
    border-radius: 100rem;
    padding: 0.75rem 1.5rem;
    background: #32b300;
    color: white;
  }
}

@media (max-width: 950px) {
  .container {
    padding-top: 7rem;
    padding-bottom: 10rem;
  }
}

@media (max-width: 550px) {
  .buttons {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-auto-flow: row;

    row-gap: 2rem;
  }
}

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  justify-items: center;

  &.first {
    margin-top: 7.5rem;
  }

  section {
    width: 100%;
    max-width: var(--section-max-width);
  }

  h2 {
    text-align: center;
    font-weight: 600;
    font-size: 1.25rem;
    color: #32B300;
    margin-bottom: 1rem;
  }

  .markdown {
    text-align: center;
  }

  form {
    padding-top: 3rem;
    margin: 0 auto;
    width: 100%;
    max-width: 50rem;
  }
}

.alert {
  * {
    color: inherit;
  }
}

.markdown {
  ul {
    color: inherit;
    line-height: 1.75;

    list-style: circle;

    margin-top: 0.5rem;
    margin-bottom: 0.25rem;

    padding: 0;
    padding-left: 1rem;
  }

  a {
    color: #3aa0ff;
  }
}

.button {
  display: inline-flex;

  --h-space: 1.5rem;
  padding: 0.75rem var(--h-space);
  border-radius: 1000rem;

  font-weight: 500;

  transition: all 150ms ease-out;

  &.inline {
    color: var(--textColor);

    &:hover {
      color: var(--hoverColor);
    }

    &:first-of-type {
      margin-left: calc(var(--h-space) * -1);
    }
  }

  &.secondary {
    border: 0.1875rem solid var(--normalColor);
    color: var(--normalColor);
    font-weight: 600;

    &:hover {
      border-color: var(--hoverColor);
      color: var(--hoverColor);
    }
  }

  &.primary {
    color: var(--textColor);
    background-color: var(--normalColor);

    &:hover {
      background-color: var(--hoverColor);
    }
  }

  & ~ &:not(.inline) {
    margin-left: var(--h-space);
  }
  
  & ~ &.inline {
    margin-left: calc(var(--h-space) / 2);
  }
}

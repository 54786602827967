.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;

  margin-bottom: 2rem;

  padding: 1rem;
  &.first {
    padding-top: 7.5rem;
  }

  section {
    width: 100%;
    max-width: var(--section-max-width);
    margin: 0 auto;

    display: grid;
    grid-auto-flow: column;
    
    row-gap: 5rem;
    column-gap: 2rem;

    margin-bottom: 3rem;

    article {
      aside {
        position: relative;
        height: 20rem;

        img {
          margin: 0 auto;
          overflow: hidden;
          border-radius: 1rem;
          max-width: 35rem;
        }
      }

      > div {
        display: grid;
        grid-auto-flow: row;
        justify-items: center;

        padding-top: 2rem;

        row-gap: 0.5rem;

        > * {
          display: inline-flex;
        }

        a {
          color: #3aa0ff;

          p {
            color: inherit;
          }
        }

        h2 {
          font-weight: 600;
          font-size: 1.15rem;
          color: #32b300;
          margin-bottom: 0.75rem;
        }

        p {
          margin: 0;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .container section {
    grid-auto-flow: row;
  }
}

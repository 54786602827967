.container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem;
  
  &.firstElement {
    padding-top: 5rem;
  }

  >section {
    width: 100%;
    max-width: var(--section-max-width);

    font-size: 1rem;

    * {
      line-height: 1.75;
    }

    h4 {
      line-height: 1.5;
      margin-bottom: 1rem;
    }

    p + p {
      margin-top: 1.5rem;
    }

    p + ul {
      margin-top: 0.5rem;
    }
  }

  a {
    color: #3aa0ff;
    font-weight: 500;

    &:hover {
      color: #000011;
    }
  }
}

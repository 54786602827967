.wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 5rem;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;

  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  background-color: #001122;
  color: white;
}

.wave {
  width: 100%;
  height: 7rem;
  transform: scaleY(0.5);
  transform-origin: top center;
  
  svg {
    width: 100%;
    height: auto;
  }
}

.section {
  max-width: var(--section-max-width);
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5rem;

  > article {
    span {
      color: #3aa0ff;
      text-transform: uppercase;
      font-size: 0.75rem;
      font-weight: 600;
    }

    h2 {
      font-weight: 600;
      font-size: 1.25rem;
      margin-bottom: 4rem;
      color: white;
    }

    p {
      line-height: 2;
      max-width: 40rem;
      color: rgba($color: white, $alpha: 0.9);
    }

  }

  > aside {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 30rem 7.5rem;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    
    div:first-of-type {
      grid-column: 1 / 5;
    }

    div {
      overflow: hidden;
      border-radius: 0.5rem;
    }
  }
}

@media (max-width: 1200px) {
  .section {
    grid-template-columns: 0.75fr 1.25fr;
  }
}

@media (max-width: 900px) {
  .section {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    > aside {
      margin-top: 2rem;
    }
  }
}

@media (max-width: 1000px) {
  .wrapper {
    margin-bottom: 2rem;
  }
}

.materialIcons {
  display: block;
  --size: 2rem;
  height: var(--size);
  width: var(--size);
  position: relative;

  &:after {
    position: relative;

    font-family: var(--font-material-icons);
    font-weight: normal;
    font-style: normal;
    font-size: var(--size); /* Preferred icon size */
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;

  margin-top: 4rem;
  margin-bottom: 2rem;

  padding: 0 1rem;

  text-align: center;

  section {
    width: 100%;
    max-width: var(--section-max-width);
  }
}

.focusBlobContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, 10rem);
  grid-auto-rows: auto;

  justify-content: space-evenly;
  column-gap: 2rem;
  row-gap: 2rem;

  margin-top: 4rem;
}

.focusBlob {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.text {
  display: flex;
  text-align: center;
  flex-direction: column;

  h3 {
    margin-bottom: 0;

    &::selection {
      color: white;
      background: var(--color);
    }
  }

  .body {
    max-width: 22.5rem;
    *::selection {
      color: white;
      background: var(--color);
    }
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 8rem;
  height: 8rem;

  margin-bottom: 0.5rem;

  svg {
    overflow: visible;
    position: absolute;
    z-index: 0;
  }
}

.materialIcons {
  display: block;
  --size: 3rem;
  height: var(--size);
  pointer-events: none;
  z-index: 2;

  &:after {
    color: white;
    position: relative;
    font-family: var(--font-material-icons);
    font-weight: normal;
    font-style: normal;
    font-size: var(--size);  /* Preferred icon size */
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }
}

.markdown {
  * + * {
    margin-top: 1.5rem;
  }
}

@media (min-width: 591px) and (max-width: 782px) {
  .focusBlobContainer {
    >*:last-child {
      justify-self: center;
      grid-column-start: span 3;
    }
  }
}

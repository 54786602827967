.section {
  position: relative;
  width: 100%;

  display: grid;
  grid-auto-flow: row;
  justify-content: center;

  text-align: center;

  background-image: url('/assets/circuit-traces-background.svg');
  background-repeat: repeat;
  background-size: contain;
  background-position: center;

  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
  margin-top: 2.5rem;
  margin-bottom: 10rem;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: #001122;
    z-index: -1;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: linear-gradient(
      0deg,
      rgba(#001122, 0) 0%,
      rgba(#001122, 1) 100%
    );
    z-index: 1;
  }

  .content {
    z-index: 2;
    height: 100%;
    width: 100%;

    display: grid;
    justify-items: center;
    grid-auto-flow: row;
    max-width: var(--section-max-width);

    .kicker {
      color: #3aa0ff;
      font-size: 0.8rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }

    h2 {
      color: white;
      display: grid;
      grid-auto-flow: row;
      margin-bottom: 2rem;
    }

    p {
      color: rgba(white, 0.8);
      max-width: 30rem;
    }
  }
}

.benefitList {
  display: grid;
  grid-auto-flow: row;
  row-gap: 15rem;

  margin-top: 10rem;
}

.benefitListItem {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  column-gap: 4rem;
  align-items: center;

  padding-left: 1rem;
  padding-right: 1rem;

  max-width: var(--section-max-width);

  .left {
    grid-template-columns: 1fr 1fr;
  }

  section {
    text-align: left;

    .markdown {
      backdrop-filter: blur(1rem);
      p {
        line-height: 2;
      }
    }
  }
}

@media (max-width: 1200px) {
  .benefitList {
    row-gap: 10rem;
    margin-top: 5rem;
  }

  .benefitListItem {
    grid-template-columns: unset;
    grid-auto-flow: row;

    section {
      display: grid;
      grid-auto-flow: row;
      justify-content: center;
      text-align: center;

      .markdown {
        p {
          max-width: 40rem;
        }
      }
    }

    .parallaxCard {
      margin-bottom: 2.5rem;
    }
  }
}

@media (max-width: 700px) {
  .benefitList {
    row-gap: 5rem;
  }
}

.wave {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 7rem;

  &.top {
    top: 0;
    transform: scaleY(-0.25);
  }

  &.bottom {
    bottom: -7rem;
    transform: scaleY(0.5);
  }

  transform-origin: top center;

  svg {
    width: 100%;
    height: auto;
  }
}

.container {
  padding-top: 2.5rem;
  padding-bottom: 5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  width: 100%;
  display: flex;
  justify-content: center;

  background-color: #001122;
}

.section {
  max-width: var(--section-max-width);
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5rem;

  >article {
    >* {
      max-width: 40rem;
    }

    a {
      color: #3aa0ff;
    }

    p {
      line-height: 2;
      color: rgba($color: white, $alpha: 0.9);
    }
  }

  >aside {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 5rem;
  }
}

.statisticsColumn {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  width: 100%;

  h3 {
    >span:first-of-type {
      font-size: 1.4rem;
      color: #3aa0ff;
      margin-right: 0.5rem;
    }

    >span:last-of-type {
      font-size: 0.9375rem;
      color: white;
    }
  }

  p {
    margin-top: 0.5rem;
    max-width: 10rem;
    font-weight: 600;
    font-size: 0.8rem;
    opacity: 0.5;
    color: white;
  }
}

@media (max-width: 1350px) {
  .section {
    grid-template-columns: 1fr max-content;

    >aside {
      display: grid;
      grid-template-columns: repeat(2, minmax(12.5rem, max-content));
      grid-template-areas: "a c" "b c";

      justify-content: start;
      align-items: center;
      row-gap: 2.5rem;
      column-gap: 2.5rem;

      article {
        p {
          max-width: 15rem;
        }

        &:nth-of-type(1) {
          grid-area: a;
        }

        &:nth-of-type(2) {
          grid-area: b;
        }

        &:nth-of-type(3) {
          grid-area: c;
        }
      }
    }
  }
}


@media (max-width: 1100px) {
  .section {
    >aside {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas: 'a' 'b' 'c';
      grid-auto-rows: 1fr;

      row-gap: 2.5rem;

      article {
        p {
          max-width: 15rem;
        }
      }
    }
  }
}

@media (max-width: 675px) {
  .section {
    grid-template-columns: 1fr;

    >aside {
      order: -1;

      margin-bottom: 2rem;
      
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: 'a b c';
      grid-auto-rows: 1fr;

      row-gap: 2.5rem;

      article {
        text-align: center;
        p {
          max-width: 15rem;
        }
      }
    }
  }
}


@media (max-width: 535px) {
  .section {
    grid-template-columns: 1fr;

    >aside {
      order: -1;

      margin-bottom: 2rem;
      
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-template-areas: 'a a' 'b c';
      grid-auto-rows: 1fr;

      row-gap: 1.5rem;

      article {
        text-align: center;

        p {
          max-width: 15rem;
          margin: 0 auto;
        }
      }
    }
  }
}


.container {
  display: flex;
  justify-content: center;
  width: 100%;

  padding: 0 1rem;
}

.header {
  width: 100%;
  max-width: var(--section-max-width);

  padding: 4rem 0rem;

  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-template-rows: minmax(40rem, auto) auto;
  grid-template-areas: 'content image' 'listItems listItems';
  column-gap: 2rem;
  justify-content: center;

  position: relative;

  &:before {
    content: '';
    position: absolute;

    top: 0;
    left:0;
    bottom: 0;
    right: 0;

    filter: blur(1rem);
  }

  .content {
    grid-area: content;
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    flex-direction: column;

    .postKicker {
      color: #4E5D69;
      font-size: 0.75rem;
      font-weight: 700;
      margin-bottom: 2rem;
      text-transform: uppercase;
    }

    h1 {
      display: flex;
      flex-direction: column;
      font-weight: 700;
      line-height: 1.5;
      margin-top: 0.5rem;
      margin-bottom: 0.25rem;
    }

    .markdown {
      color: #4E5D69;
      max-width: 30rem;
    }
  }

  .aside {
    grid-area: image;

    display: flex;
    justify-content: center;
    align-items: center;

    .heroImageContainer {
      position: relative;

      padding: 0rem 4rem;

      img {
        --radius-large: 10rem;
        --radius-small: calc(var(--radius-large) / 2);
        border-top-left-radius: var(--radius-large);
        border-top-right-radius: var(--radius-small);
        border-bottom-left-radius: var(--radius-small);
        border-bottom-right-radius: var(--radius-large);

        overflow: hidden;
      }
    }
  }
}

@media (max-width: 1300px) {
  .header .aside .heroImageContainer img {
    --radius-large: 8rem;
  }
}

@media (max-width: 1000px) {
  .header .aside .heroImageContainer img {
    --radius-large: 12rem;
    --radius-small: 4rem;
  }
}

@media (max-width: 800px) {
  .header .aside .heroImageContainer img {
    --radius-large: 8rem;
    --radius-small: 2rem;
  }
}

@media (max-width: 600px) {
  .header .aside .heroImageContainer img {
    --radius-large: 6rem;
    --radius-small: 1rem;
  }
}

.transactions {
  position: absolute;
  bottom: 0;
  left: 0;

  max-width: 20rem;

  display: grid;
  row-gap: 0.5rem;

  transform: translateY(2.5rem);
}

.transaction {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-template-areas: 'amount title' 'amount who';

  align-content: center;
  align-items: center;

  column-gap: 1rem;
  row-gap: 0.35rem;

  .amount {
    grid-area: amount;
    font-size: 1.75rem;
    font-weight: 400;
    font-family: var(--font-roboto-mono);
    line-height: 1;

    color: rgba(#4E5D69, 0.9);

    margin: 0;
    &.outgoing {
      &:before {
        content: '-';
      }
    }

    &.incoming {
      &:before {
        content: '+';
      }
    }
  }

  .title, .who {
    margin: 0;
    line-height: 1;
  }

  .title {
    grid-area: title;
    font-size: 0.875rem;
  }

  .who {
    grid-area: who;
    font-size: 0.8125rem;
    text-transform: uppercase;
    font-weight: 500;
  }
}

.transaction, .task {
  box-shadow:
    0px 0.9px 1.5px -5px rgba(0, 0, 0, 0.014),
    0px 2.1px 3.7px -5px rgba(0, 0, 0, 0.02),
    0px 4px 6.9px -5px rgba(0, 0, 0, 0.025),
    0px 7.1px 12.3px -5px rgba(0, 0, 0, 0.03),
    0px 13.4px 23px -5px rgba(0, 0, 0, 0.036),
    0px 32px 55px -5px rgba(0, 0, 0, 0.05);

  width: 100%;

  background-color: white;

  border-radius: 0.75rem;
  padding: 1rem 1.5rem;
}

.tasks {
  position: absolute;
  top: 0;
  right: 0;

  max-width: 20rem;

  transform: translateY(-2.5rem);

  display: grid;
  row-gap: 1rem;
}

.task {
  display: grid;
  grid-template-columns: 2rem 1fr;

  column-gap: 1rem;
  row-gap: 0.25rem;

  align-content: center;
  align-items: center;

  .title {
    margin: 0;
    line-height: 1;
  }
}

@media (max-width: 1300px) {
  .task, .transaction {
    padding: 0.75rem 1rem;

  }

  .task {
    grid-template-columns: 1.5rem 1fr;
    column-gap: 0.5rem;

    h4 {
      font-size: 0.875rem;
    }

    .materialIcons {
      --size: 1.5rem;
    }
  }
}

@media (max-width: 1000px) {
  .header .aside {
    padding: 5rem;
  }

  .task, .transaction {
    padding: 1rem 1.5rem;
  }
}

@media (max-width: 800px) {
  .header .aside {
    padding: 2rem;
  }

  .task, .transaction {
    padding: 0.75rem 1rem;
  }
}

@media (max-width: 600px) {
  .header .aside {
    padding: 0rem 1rem;
  }

  .task, .transaction {
    padding: 0.75rem 1rem;
  }

  .task:not(:first-of-type), .transaction:not(:first-of-type) {
    display: none;
  }
}

.buttons {
  margin-top: 2rem;
}

.listItems {
  margin-top: 2rem;
  grid-area: listItems;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(max-content, 15rem));
  justify-content: start;
  column-gap: 2rem;
  row-gap: 3rem;
  width: 100%;

  .listItem {
    column-gap: 1.25rem;

    > div > span {
      height: 3.5rem;
      width: 3.5rem;

      &:after {
        font-size: 1.5rem;
      }
    }

    > div {
      h4 {
        margin: 0;
        line-height: 1.25;
        margin-bottom: 0.4rem;
      }

      p {
        margin: 0;
        line-height: 1;
        font-weight: 600;
        color: #4E5D69;
      }
    }
  }
}

.markdown {
  p:not(last-of-type) {
    margin-bottom: 1rem;
  }
}

.materialIcons {
  display: block;
  --size: 2rem;
  height: var(--size);
  width: var(--size);
  position: relative;

  &:after {
    position: relative;

    font-family: var(--font-material-icons);
    font-weight: normal;
    font-style: normal;
    font-size: var(--size); /* Preferred icon size */
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }
}

@media (max-width: 1200px) {
  .header {
    column-gap: 2rem;
    grid-template-columns: 0.8fr 1fr;
  }
}

@media (max-width: 1000px) {
  .header {
    column-gap: 2rem;
    row-gap: 2rem;

    grid-template-columns: 1fr;
    grid-template-rows: minmax(25rem, 35vh) auto auto;
    grid-template-areas: 'image' 'content' 'listItems';

    .content {
      text-align: center;

      h1 {
        margin-top: 0;
      }

      p {
        max-width: unset;
      }
    }

    .listItems {
      justify-content: center;
    }
  }
}

@media (max-width: 800px) {
  .header {
    grid-template-rows: minmax(25rem, 25vh) auto auto;
  }
}

@media (max-width: 600px) {
  .header {
    grid-template-rows: minmax(25rem, 20vh) auto auto;

    .aside .heroImageContainer {
      padding: 0rem 1rem;
    }
  }
}

@media (max-width: 450px) {
  .header {
    grid-template-rows: minmax(17.5rem, 20vh) auto auto;

    .aside .heroImageContainer {
      padding: 0rem 1rem;
    }
  }
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0rem 1rem;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;


  max-width: 1680px;
  width: 100%;

  h2 {
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  .clients {
    padding-top: 1.5rem;
    padding-bottom: 3rem;

    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    width: 100%;

    >* {
      margin: 2rem 0;
    }

    >* ~ *:not(:last-child) {
      margin: inherit 1.5rem;
      padding: 0 1.5rem;
    }
  }
}

.logoContainer {
  position: relative;
  height: 2rem;
  width: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 100%;
    width: auto;
  }
}

.container {
  display: grid;
  grid-template-columns: minmax(auto, max-content);
  transform-style: preserve-3d;
  margin: 2rem;
  aspect-ratio: 16 / 9;

  video {
    position: relative;
    aspect-ratio: 16 / 9;
    width: 100%;
    max-width: 40rem;
    height: auto;
  }
}

.videoContainer {
  border-radius: 1rem;
  overflow: hidden;

  .shine {
    overflow: hidden;

    aspect-ratio: 16 / 9;

    display: block;
    content: '';
    position: absolute;

    --sizeAdjustment: 0.2rem;
    --offset: calc(var(--sizeAdjustment) / 2);

    border-radius: calc(1rem + var(--offset));
    
    top: calc(0% - var(--offset));
    left: calc(0% - var(--offset));

    width: calc(100% + var(--sizeAdjustment));
    height: calc(100% + var(--sizeAdjustment));

    background-size: 100% 100%;
    background-position: 0% 0%;

    z-index: -1;
  }
}

.background {
  position: absolute;

  --h-offset: -1.75rem;
  --v-offset: -1.5rem;

  left: var(--h-offset);
  right: var(--h-offset);
  top: var(--v-offset);
  bottom: var(--v-offset);

  border-radius: 1.15rem;
  
  transform: translateZ(-5rem);
}

@media (max-width: 1200px) {
  .background {
    --h-offset: -1.75rem;
    --v-offset: -1.15rem;
  }

  .container {
    margin: 1rem;
  }
}

@media (max-width: 700px) {
  .background {
    --h-offset: -1.5rem;
    --v-offset: -0.9rem;
  }
}

@media (max-width: 550px) {
  .background {
    --h-offset: -1.25rem;
    --v-offset: -0.85rem;
  }
}

@media (max-width: 400px) {
  .background {
    --h-offset: -0.9rem;
    --v-offset: -0.75rem;
  }
}

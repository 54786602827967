.container {
  display: flex;
  justify-content: center;
  width: 100%;

  padding-top: 5rem;
  padding-bottom: 2.5rem;

  background-image: url("/assets/icon-background.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat space;
}

.section {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 4rem;

  justify-items: center;
  align-items: start;

  width: 100%;

  figure {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 1.5rem;
    max-width: 50rem;
    
    &:nth-of-type(2n)  {
      .avatarContainer {
        order: 2;
      }
    }

    .content {
      padding: 1.5rem;
      border-radius: 0.5rem 0.5rem 3rem 0.5rem;

      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(0.75rem);
    }

    blockquote {
      font-size: 1rem;
      padding: 0;
      margin: 0;
      margin-bottom: 1rem;

      p {
        margin: 0;
        line-height: 1.75;
      }
    }

    figcaption {
      font-size: 0.875rem;

      cite {
        display: block;
        font-style: normal;
        font-weight: 700;
        color: #4E5D69;
        opacity: 0.75;
        margin-bottom: 0.5rem;
      }

      div {
        color: #4E5D69;
        opacity: 0.5;
      }
    }
  }
}

@media (max-width: 850px) {
  .section {
    figure {
      max-width: 80%;
    }
  }
}

@media (max-width: 500px) {
  .container {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .section {
    figure {
      justify-items: center;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      max-width: 90%;

      .avatarContainer {
        order: 0;
      }
      
      &:nth-of-type(2n) {
        .avatarContainer {
          order: 0;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .container {
    background-size: cover;
  }
}

.avatar {
  height: 5.5rem;
  width: 5.5rem;
  position: relative;
  z-index: 1;
}

.svg {
  position: absolute;
}

.avatarContainer {
  margin-top: 1rem;
  position: relative;
}

.bgBlob {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scale(1.05);
}

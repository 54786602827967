.wrapper {
  display: grid;
  width: 100%;
  justify-items: center;

  padding-top: 7rem;
  padding-bottom: 7rem;

  margin-bottom: 2.5rem;
}

.section {
  display: grid;
  grid-auto-rows: auto;
  align-items: center;
  max-width: var(--section-max-width);
  text-align: center;

  padding: 0rem 1rem;

  h2 {
    color: #4E5D69;
    font-size: 1.5rem;
  }

  p {
    max-width: 30rem;
    font-size: 1rem;
    line-height: 2;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #3aa0ff;
  border-radius: 1rem;

  text-align: center;

  padding: 5rem 3rem;

  h2 {
    color: white;
  }

  .markdown {
    font-weight: 500;
    max-width: 35rem;
    color: white;

    p {
      color: white;
      &:not(:first-of-type) {
        margin-top: 3rem;
      }
    }
  }
}

.listItems {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, max-content));
  justify-content: space-evenly;
  align-items: baseline;

  width: 100%;
  margin-bottom: 7.5rem;
  padding: 1rem;

  row-gap: 5rem;

  .listItem {
    column-gap: 2rem;

    h4 {
      margin: 0;
    }

    p {
      margin: 0;
      font-weight: 600;
      color: #4E5D69;
    }
  }
}

.buttons {
  margin-top: 2rem;

  font-weight: 600;

  display: grid;
  align-items: center;
  justify-items: center;
  grid-auto-flow: column;

  column-gap: 2rem;

  a[data-type=primary] {
    border-radius: 100rem;
    padding: 0.75rem 1.5rem;
  }
}

@media (min-width: 766px) and (max-width: 1006px) {
  .listItems .listItem:nth-child(even):last-child {
    justify-self: center;
    grid-column-start: span 3;
  }
}

.section {
  position: relative;
  width: 100%;

  display: grid;
  justify-items: center;
  grid-auto-flow: row;
  row-gap: 2.5rem;

  text-align: center;
  padding: 5rem 2.5rem;
  padding-bottom: 2.5rem;

  margin-bottom: 4rem;

  .content {
    height: 100%;
    width: 100%;

    max-width: var(--section-max-width);

    display: flex;
    justify-content: center;
    flex-direction: column;

    background-image: url('/assets/teacher-benefits-background.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    padding: 4rem 0;

    .kicker {
      color: #32b300;
      font-size: 0.8rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }

    h2 {
      color: #4e5d69;
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
    }

    .markdown {
      display: flex;
      flex-direction: column;
      align-items: center;

      line-height: 1.75;
    }

    p {
      max-width: 30rem;
      &:not(last-of-type) {
        margin-bottom: 0.5rem;
      }
    }
  }

  .listItems {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 20rem));
    justify-content: space-evenly;
    align-items: baseline;

    row-gap: 5rem;
    column-gap: 6rem;

    margin-top: 5rem;

    .listItem {
      max-width: 20rem;
    }
  }
}

.buttons {
  margin-top: 1rem;
}

@media (max-width: 1250px) {
  .section {
    padding: 1rem 1rem;
    .content {
      background: none;
      padding: 0;
    }
  }
}

@media (min-width: 830px) and (max-width: 1250px) {
  .section .listItems {
    /* Center odd last child if it's overflowing */
    .listItem:nth-child(odd):last-of-type {
      grid-column-start: span 2;
      justify-self: center;
    }
  }
}

@media (min-width: 1184px) and (max-width: 1662px) {
  .section .listItems {
    /* Center odd last child if it's overflowing */
    .listItem:nth-child(even):last-of-type {
      grid-column-start: span 3;
      justify-self: center;
    }
  }
}

.container {
  display: grid;
  grid-template-columns: max-content auto;
  column-gap: 2.25rem;
  align-items: center;
}

.column {
  grid-template-rows: auto auto;
  column-gap: unset;
  row-gap: 2.25rem;
  justify-items: center;
  grid-template-columns: 1fr;
  text-align: center;
}

.content {
  h4 {
    font-weight: 600;
    font-size: 1.05rem;
    line-height: 2;
    margin: 0;
    color: #4E5D69;
  }

  > div {
    margin: 0;
  }

  p {
    &:first-of-type {
      margin-top: 0.5rem;
    }
  }
}

.materialIcons {
  display: block;

  &:after {
    padding: 1rem;
    border-radius: 1000rem;

    position: relative;
    font-family: var(--font-material-icons);
    font-weight: normal;
    font-style: normal;
    font-size: 2.25rem;  /* Preferred icon size */
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }
}

.section {
  position: relative;
  width: 100%;

  display: flex;
  justify-content: center;

  padding: 5rem 2rem;
  padding-top: 2.5rem;
  padding-bottom: 5rem;

  .grid {
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    grid-column-gap: 5rem;

    width: 100%;
    max-width: var(--section-max-width);
  }

  .content {
    height: 100%;
    width: 100%;

    display: grid;
    justify-content: center;
    grid-auto-flow: row;

    .kicker {
      font-size: 0.8rem;
      font-weight: 700;
      text-transform: uppercase;
    }

    h2 {
      color: #4E5D69;
      display: flex;
      flex-direction: column;
    }

    .markdown {
      margin-bottom: 3rem;
      max-width: 30rem;
    }

    .listItem {
      margin-bottom: 2rem;
      align-items: start;

      > div > span {
        margin-top: 2rem;
      }

      h4 {
        margin: 0;
      }

      p {
        margin-top: 0;
      }
    }
  }

  .aside {
    padding: 5rem 0rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;

    max-height: 75%;

    .imageContainer {
      height: 100%;
      width: 100%;
      position: relative;

      &:first-of-type {
        img {
          border-top-left-radius: 2rem;
          border-top-right-radius: 6rem;
          border-bottom-left-radius: 6rem;
          border-bottom-right-radius: 2rem;
        }
      }

      &:last-of-type {
        transform: translateY(10rem);
        img {
          border-top-left-radius: 6rem;
          border-top-right-radius: 2rem;
          border-bottom-left-radius: 2rem;
          border-bottom-right-radius: 6rem;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .section {
    padding-top: 5rem;
    padding-bottom: 2.5rem;

    .grid {
      grid-template-columns: unset;
      grid-auto-flow: row;
      grid-auto-rows: auto;

      .aside {
        width: 100%;
        height: 25rem;
        padding: unset;

        .imageContainer {
          &:first-of-type {
            transform: translateY(-3rem);
          }

          &:last-of-type {
            transform: translateY(3rem);
          }
        }
      }

      .content {
        justify-content: center;
        justify-items: center;

        p {
          max-width: unset;
        }
      }
    }
  }
}

.gradientCanvas {
  position: absolute;
  opacity: 0.25;
  width: 100%;
  height: 100%;
  --gradient-color-1: #adfb8e;
  --gradient-color-2: #fbe05b;
  --gradient-color-3: #fbe05b;
  --gradient-color-4: #ff94a9;
  z-index: -1;
}

.hero {
  position: relative;
  height: 70vh;
  max-height: min(100vh, 50rem);
  min-height: 50rem;

  width: 100%;

  padding-left: 1rem;
  padding-right: 1rem;

  padding-bottom: 7.5rem;

  display: flex;
  justify-content: center;

  .grid {
    display: grid;
    grid-template-columns: 0.75fr 1.25fr;
    grid-column-gap: 2rem;

    width: 100%;
    max-width: var(--section-max-width);
  }

  .content {
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    flex-direction: column;

    margin-top: 2rem;

    .kicker {
      color: #4e5d69;
      font-size: 1rem;
      font-weight: 700;
    }

    h1 {
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
    }

    p {
      max-width: 30rem;
    }
  }

  .video {
    padding: 8rem 0rem;

    .bgBlob {
      transform: translateX(-0.75rem) scale(0.95);
    }

    .blobContainer {
      height: 100%;
      width: 100%;
      position: relative;

      max-height: 32.5rem;
    }
  }

  .notifications {
    display: grid;
    grid-row-gap: 1rem;
  }
}

.blobWrapper {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.blobs {
  display: block;

  aspect-ratio: 87 / 52;
}

.blobBubble {
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  --h-padding: 1rem;
  --v-padding: 2rem;
  padding: var(--v-padding) var(--h-padding);

  p {
    font-size: 0.8175rem;
    font-weight: 600;
    max-width: 12rem;
    line-height: 1.5;
    text-align: center;

    margin-top: 0.5rem;
    max-width: 10rem;
  }

  // Digitaalne ja füüsiline maailm
  &:nth-of-type(1) {
    top: 0;
    left: 4vw;
    margin-left: calc(var(--h-padding) * -1);
    margin-bottom: 2rem;

    p {
      padding: 0 1.75rem;
      max-width: 10rem;
    }
  }

  // Emotsioonidest laetud õppekeskkond
  &:nth-of-type(2) {
    bottom: -5rem;
    left: 40%;
    margin-bottom: calc(var(--v-padding) * -1);
  }

  // Eesmärgistatud ja struktureeritud
  &:nth-of-type(3) {
    top: 0;
    right: 4vw;
    margin-right: calc(var(--h-padding) * -1);
  }
}

.materialIcons {
  display: block;
  --size: 2rem;
  height: var(--size);
  width: var(--size);
  position: relative;

  &:after {
    position: relative;

    font-family: var(--font-material-icons);
    font-weight: normal;
    font-style: normal;
    font-size: var(--size); /* Preferred icon size */
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }
}

@media (max-width: 1200px) {
  .hero {
    height: 45rem;
    padding-bottom: 0rem;

    .grid {
      grid-template-columns: 0.9fr 1.1fr;
    }

    .video {
      padding-right: 2rem;
      .bgBlob {
        transform: translateX(-0.75rem) scale(1.05);
      }
    }
  }

  .blobBubble {
    &:nth-of-type(1) {
      top: 0;
      left: -7.5rem;
    }

    &:nth-of-type(2) {
      bottom: -1.5rem;
      left: 27.5%;
    }

    &:nth-of-type(3) {
      top: -2rem;
      right: -2rem;
    }
  }

  .materialIcons {
    --size: 1.75rem;
  }
}

@media (max-width: 975px) {
  .hero {
    height: 65rem;
    padding-bottom: 5rem;

    max-height: unset;

    .grid {
      padding-top: 7.5rem;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      row-gap: 6rem;

      .content {
        order: 2;
        text-align: center;

        p {
          max-width: unset;
        }

        .buttons {
          justify-content: center;
        }
      }
    }

    .video {
      padding: unset;
      .bgBlob {
        transform: translateX(-0.75rem) scale(0.925);
      }
    }
  }

  .blobWrapper {
    justify-content: center;
  }

  .blobBubble {
    &:nth-of-type(1) {
      top: 0;
      left: 12vw;
    }

    &:nth-of-type(2) {
      bottom: -4rem;
      left: 37%;
    }

    &:nth-of-type(3) {
      top: -1rem;
      right: 10vw;
    }
  }

  .materialIcons {
    --size: 1.75rem;
  }
}

@media (max-width: 700px) {
  .hero .video {
    padding: 0rem 0rem;

    .bgBlob {
      transform: translateX(-0.75rem) scale(0.95);
    }
  }

  .blobBubble {
    --h-padding: 1rem;
    --v-padding: 1.5rem;

    p {
      max-width: 10rem;
    }

    &:nth-of-type(1) {
      top: -0rem;
      left: 0;
    }

    &:nth-of-type(2) {
      bottom: -5rem;
      left: 30%;
    }

    &:nth-of-type(3) {
      top: -1rem;
      right: 2rem;
      --v-padding: 2rem;
    }
  }
}

@media (max-width: 600px) {
  .hero {
    min-height: 80rem;
  }

  .blobs {
    display: grid;
    grid-template-columns: repeat(auto-fit, 9rem);
    column-gap: 1rem;
    justify-content: space-evenly;
    align-items: end;

    transform: translateY(4rem);
    width: 100%;
  }

  .blobBubble {
    position: relative;
    &:nth-of-type(1) {
      top: unset;
      bottom: unset;
      left: unset;
      right: unset;
      margin: unset;
    }

    &:nth-of-type(2) {
      top: unset;
      bottom: unset;
      left: unset;
      right: unset;
      margin: unset;

      transform: translateY(2rem);
    }

    &:nth-of-type(3) {
      top: unset;
      bottom: unset;
      left: unset;
      right: unset;
      margin: unset;
    }
  }
}

@media (max-width: 510px) {
  .hero {
    .grid {
      row-gap: 10rem;
    }
  }

  .blobs {
    transform: translateY(10rem);
    justify-content: space-between;
  }

  .blobBubble {
    &:nth-of-type(3) {
      transform: translateY(-2.5rem);
      grid-column: span 2;
      justify-self: center;
      --v-padding: 2rem;
    }
  }
}

@media (max-width: 450px) {
  .hero .video {
    .bgBlob {
      transform: translateX(-0.5rem) scale(0.975);
    }
  }

  .blobBubble {
    &:nth-of-type(3) {
      padding-top: 1rem;
      transform: translateX(-0.5rem) translateY(-1.5rem);
    }
  }

  .hero .content h1 {
    font-size: 1.5rem;
  }
}

@media (max-width: 400px) {
  .hero {
    .video {
      .bgBlob {
        transform: translateX(-0.25rem) scale(1.025);
      }
    }
  }

  .blobs {
    transform: translateY(7.5rem);
    p {
      font-size: 0.8rem;
    }
  }

  .blobBubble {
    &:nth-of-type(3) {
      transform: translateX(-1rem) translateY(0rem);
    }
  }

  .materialIcons {
    --size: 1.25rem;
  }
}

.buttons {
  margin-top: 2rem;
  margin-bottom: 3rem;

  font-weight: 600;

  display: grid;
  align-items: center;
  justify-content: start;
  grid-auto-flow: column;

  a[data-type='primary'] {
    border-radius: 100rem;
    padding: 0.75rem 1.5rem;
  }
}

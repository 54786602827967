.notification {
  display: grid;
  grid-template-columns: 2rem 1fr 1.5rem;
  grid-template-rows: auto auto;

  column-gap: 1.5rem;
  row-gap: 0.35rem;

  align-items: center;

  grid-template-areas: 'icon title arrow' 'icon body arrow';

  background: rgba(white, 0.5);
  backdrop-filter: blur(1rem);

  border-radius: 1rem;
  padding: 1rem;

  transition: transform 150ms ease-out;

  &:hover {
    transform: translateY(-0.25rem) scale(1.025);
  }
}

.iconContainer {
  grid-area: icon;

  .materialIcons {
    --size: 2.5rem;
  }
}

.title {
  grid-area: title;

  font-size: 0.9375rem;
}

.body {
  grid-area: body;

  * {
    line-height: 1.5;
    font-size: 0.875rem;
    color: rgba(#4e5d69, 0.75);
    font-weight: 500;
    text-wrap: balance;
  }
}

.arrowContainer {
  grid-area: arrow;
  .materialIcons {
    --size: 1.5rem;
  }
}

.materialIcons {
  display: block;
  --size: 2rem;
  height: var(--size);
  width: var(--size);
  position: relative;

  &:after {
    position: relative;

    font-family: var(--font-material-icons);
    font-weight: normal;
    font-style: normal;
    font-size: var(--size); /* Preferred icon size */
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }
}

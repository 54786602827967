.map {
  width: 100%;
  max-height: 50rem;
  aspect-ratio: 18 / 9;
  position: relative;
  pointer-events: none;
}

.mapSection {
  width: 100%;
  background: #001122;
  position: relative;
}

.header {
  padding-top: 5rem;

  text-align: center;

  .title {
    color: white;
    font-size: 1.35rem;
    font-weight: 700;
  }

  .kicker {
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 700;
    color: #3aa0ff;
  }
}

.wave {
  position: absolute;
  z-index: 10;
  top: 0;
  width: 100%;
  height: 7rem;
  transform: scaleY(-0.25);
  transform-origin: top center;
  
  svg {
    width: 100%;
    height: auto;
  }
}
